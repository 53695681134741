<template>
  <div class="internal">
    <button @click="refresh">REFRESH</button>
    <div class="results" v-if="refreshed">
      <p><strong>ENVIRONMENT</strong>: {{ environmentId || "???" }}</p>
      <p><strong>USER</strong>: {{ userId || "???" }}</p>
      <div class="devices">
        <ul>
          <li v-for="device in devices" :key="device.deviceId">
            {{ device.kind }}: {{ device.label }}
            <code>{{ device.deviceId }}</code>
          </li>
        </ul>
      </div>
      <video ref="video"></video>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "InternalCamtest",
  data: () => {
    return {
      refreshed: false,
      environmentId: undefined as undefined | string,
      userId: undefined as undefined | string,
      devices: [] as MediaDeviceInfo[],
    };
  },
  methods: {
    refresh(): void {
      this.refreshed = true;
      if (!navigator.mediaDevices) {
        alert("mediaDevices not supported.");
        return;
      }

      this.enumerate();

      navigator.mediaDevices
        .getUserMedia({
          video: { facingMode: "environment" },
        })
        .then((stream) => {
          this.environmentId = stream.getVideoTracks()[0].label;
          this.enumerate();
          (this.$refs.video as HTMLVideoElement).srcObject = stream;
          (this.$refs.video as HTMLVideoElement).play();
        });

      navigator.mediaDevices
        .getUserMedia({
          video: { facingMode: "user" },
        })
        .then((stream) => {
          this.userId = stream.getVideoTracks()[0].label;
          this.enumerate();
        });
    },
    enumerate() {
      if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        alert("enumerateDevices() not supported.");
        return;
      }
      navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {
          this.devices = devices;
        })
        .catch(function (err) {
          alert(err);
        });
    },
  },
});
</script>

<style lang="scss">
.internal {
  padding: 2rem;
  background: white;
  width: 100%;
  min-height: 100vh;

  font-family: sans-serif;
  font-size: 20pt;

  video {
    min-width: 256px;
    min-height: 256px;
    background: lightcoral;
  }
}

.qr-codes {
  display: flex;
  flex-wrap: wrap;
}

.qr-code {
  margin: 1em;
  border: 1px solid black;

  img {
    width: 256px;
    height: 256px;
  }

  label {
    display: block;
    text-align: center;
    margin-bottom: 1em;
    font-weight: bold;
    font-family: monospace;
    font-size: 20pt;
  }
}
</style>
