
import { defineComponent } from "vue";

export default defineComponent({
  name: "InternalCamtest",
  data: () => {
    return {
      refreshed: false,
      environmentId: undefined as undefined | string,
      userId: undefined as undefined | string,
      devices: [] as MediaDeviceInfo[],
    };
  },
  methods: {
    refresh(): void {
      this.refreshed = true;
      if (!navigator.mediaDevices) {
        alert("mediaDevices not supported.");
        return;
      }

      this.enumerate();

      navigator.mediaDevices
        .getUserMedia({
          video: { facingMode: "environment" },
        })
        .then((stream) => {
          this.environmentId = stream.getVideoTracks()[0].label;
          this.enumerate();
          (this.$refs.video as HTMLVideoElement).srcObject = stream;
          (this.$refs.video as HTMLVideoElement).play();
        });

      navigator.mediaDevices
        .getUserMedia({
          video: { facingMode: "user" },
        })
        .then((stream) => {
          this.userId = stream.getVideoTracks()[0].label;
          this.enumerate();
        });
    },
    enumerate() {
      if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        alert("enumerateDevices() not supported.");
        return;
      }
      navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {
          this.devices = devices;
        })
        .catch(function (err) {
          alert(err);
        });
    },
  },
});
